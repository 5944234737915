
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useModels, useDeleteModel } from '@/composables/api';
import { ModelIdOptions } from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Gender',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Male',
        value: 'male'
      },
      {
        label: 'Female',
        value: 'female'
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: 'Is Popular',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Yes',
        value: '1'
      },
      {
        label: 'No',
        value: '0'
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const gender = ref();
    const isPopular = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');

    const { data, isLoading, isFetching, refetch } = useModels({ page, keyword, isPopular, gender, sortBy, descSortBy });
    const route = useRoute();
    const { isLoading: isDeletedLoading, mutate } = useDeleteModel();
    const statusIndex = ref(undefined);
    const { t } = useI18n();

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const handleFilterChange = (event: FilterEvent) => {
      gender.value = event[0];
      isPopular.value = event[1];
    };

    const deleteModel = ({ modelId }: ModelIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { modelId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeletedLoading,
      sortChange,
      deleteModel,
      statusIndex,
      searchKeyword,
      handleFilterChange,
      t,
      FILTER_OPTIONS
    };
  }
});
